<template>
  <div class="supplier">
    <supplier-menu :active="1" />
    <supplier-filter :gradeActives.sync="gradeActives" @change="filterChange" />
    <supplier-sort :hasPays.sync="hasPays" @change="sortChange" />
    <div class="data-list">
      <div class="content">
        <div class="data-list-main flex_b">
          <div class="data-list-l">
            <div class="not-data" v-if="!datalist.length && !loading">
              无数据
            </div>
            <div class="loading" v-if="loading">
              <i class="el-icon-loading"></i>数据加载中...
            </div>
            <div class="list-main">
              <div
                class="item flex_b"
                @click="goDetail(item)"
                v-for="item in datalist"
                :key="item.id"
              >
                <el-image :src="item.logo"></el-image>
                <div class="info flex_b">
                  <div class="info-l">
                    <div class="title">
                      <h3>
                        {{ item.name }}
                      </h3>
                      <i
                        class="iconfont icon-auth"
                        v-if="item.yzCompanyAuthItems"
                      ></i>
                      <span
                        ><i class="iconfont icon-dizhi1"></i>
                        {{ item.areaTreeNames }}</span
                      >
                    </div>
                    <div class="else">
                      <span>成交额：{{ item.alerdyServiceTotalMoney }}万</span>|
                      <span>好评率：{{ item.goodRate }}%</span>|
                      <span>已服务雇主：{{ item.orderTotalCount }}家</span>
                    </div>
                    <div class="label">
                      <span
                        v-for="items in item.yzCompanyAuthItems"
                        :key="items"
                        >{{ companyJson[items] }}</span
                      >
                    </div>
                    <div class="desc">
                      雇主评价：{{ item.issueTitle || "暂无评价" }}
                    </div>
                    <div class="btns">
                      <p class="common-btn">进入店铺</p>
                      <p class="common-btn" @click.stop="goChat(item)">
                        免费咨询
                      </p>
                    </div>
                  </div>
                  <div class="info-r" @click.stop="goServers(item)">
                    <el-image :src="item.serviceLogo"></el-image>
                  </div>
                </div>
              </div>
              <div class="page">
                <el-pagination
                  background
                  :page-size="pageSize"
                  :current-page.sync="pageNo"
                  @current-change="currentChange"
                  :layout="`prev, pager, next${total > 120 ? ',jumper' : ''}`"
                  prev-text="上一页"
                  next-text="下一页"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
            <hot mode="supplier" />
          </div>
          <div class="data-list-r">
            <fast-up />
            <ad />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import supplierMenu from "@/components/servers/supplier-menu";
import supplierFilter from "@/components/servers/supplier-filter";
import supplierSort from "@/components/servers/supplier-sort";
import hot from "@/components/servers/hot";
import ad from "@/components/servers/ad";
import fastUp from "@/components/servers/fastUp";
export default {
  components: {
    supplierMenu,
    supplierFilter,
    supplierSort,
    hot,
    ad,
    fastUp,
  },
  data() {
    return {
      upVisible: false,
      pageNo: 1,
      pageSize: 12,
      total: 0,
      datalist: [],
      companyJson: {},
      loading: false,
      gradeActives: "",
      hasPays: false,
      params: {
        categoryCode: "",
        areaCode: "",
        yzCompanyAuthItems: "",
      },
    };
  },
  methods: {
    filterChange({ categoryCode, areaCode, yzCompanyAuthItems }) {
      this.params = {
        categoryCode: categoryCode || "",
        areaCode: areaCode || "",
        yzCompanyAuthItems: yzCompanyAuthItems || "",
        orderBy: this.params.orderBy,
      };
      this.hasPays = yzCompanyAuthItems == "4";
      // document.documentElement.scrollTop =
      //   document.querySelector(".sort-main").offsetTop - 150;
      this.getData();
    },
    sortChange({ hasPay, areaCode, orderBy }) {
      if (hasPay) {
        this.gradeActives = "4";
      } else if (this.gradeActives == "4") {
        this.gradeActives = "";
      }
      this.params = {
        areaCode: areaCode || "",
        yzCompanyAuthItems: this.gradeActives,
        orderBy: orderBy || "",
      };
      this.getData();
    },
    currentChange(val) {
      this.pageNo = val;
      this.getData();
    },
    goDetail(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `https://www.yizao2025.com/f/shop-${item.companyId}.html`;
    },
    goServers(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `https://www.yizao2025.com/f/shopservie-view-${item.cropCode}-${item.serviceId}.html`;
    },
    goChat(item) {
      var tempwindow = window.open("_blank");
      tempwindow.location.href =
        "https://www.yizao2025.com/f/contactKefu?company_id=" + item.companyId;
    },
    getData() {
      this.loading = true;
      this.datalist = [];
      this.$api
        .get("/api/v1/getYzShopInfoList", {
          params: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            ...this.params,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.list) {
            this.datalist = res.data.list.map((item) => {
              item.yzCompanyAuthItems = item.yzCompanyAuthItems
                ? item.yzCompanyAuthItems.split(",")
                : [];
              if (item.recService) {
                let arr = item.recService.split("|");
                item.serviceLogo = arr.length > 2 ? arr[2] : "";
                item.serviceId = arr.length > 4 ? arr[4] : "";
              }
              return item;
            });
          }
          this.total = res.data.count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  mounted() {
    let pid = this.$route.query.pid;
    let id = this.$route.query.id;
    if (pid && !id) {
      this.params.categoryCode = pid;
    } else if (pid && id) {
      this.params.categoryCode = id;
    }
    this.$sapi
      .get("/api/v1/getDictDataList-yz_company_auth_items.json")
      .then((res) => {
        res.forEach((item) => {
          this.companyJson[item.dictValue] = item.dictLabel;
        });
        this.getData();
      });

    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/supplier.scss";
</style>
<template>
  <div class="sort">
    <div class="content">
      <div class="sort-main flex_b">
        <div class="sort-l">
          <div
            :class="['item', sortActive == 1 ? 'on' : '']"
            @click="sortChange(1)"
          >
            综合排序
          </div>
          <div
            :class="['item', sortActive == 2 ? 'on' : '']"
            @click="sortChange(2)"
          >
            最新
            <span class="el-icon-bottom" v-if="!sortNewUp"></span>
            <span class="el-icon-top" v-if="sortNewUp"></span>
          </div>
          <div
            :class="['item', sortActive == 3 ? 'on' : '']"
            @click="sortChange(3)"
          >
            热度
            <span class="el-icon-bottom" v-if="!sortHotUp"></span>
            <span class="el-icon-top" v-if="sortHotUp"></span>
          </div>
          <div
            :class="['item', sortActive == 4 ? 'on' : '']"
            @click="sortChange(4)"
          >
            好评率
            <span class="el-icon-bottom" v-if="!sortPriceUp"></span>
            <span class="el-icon-top" v-if="sortPriceUp"></span>
          </div>
        </div>
        <div class="sort-r">
          <el-checkbox v-model="hasPay" @change="change"
            >已缴纳保障金</el-checkbox
          >
          <div class="site-check">
            <span>收货地：</span>
            <el-cascader
              v-model="siteCheck"
              size="small"
              :options="provinceAndCityDataPlus"
              @change="siteChange"
              :props="{
                value: 'areaCode',
                label: 'areaName',
                children: 'childList',
                leaf: 'leaf',
              }"
            ></el-cascader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    hasPays: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      hasUrgent: false,
      yzShopMainList: [],
      sortActive: 1,
      siteCheck: [],
      sortPriceUp: true,
      sortNewUp: false,
      sortHotUp: false,
      provinceAndCityDataPlus: [],
      areaCode: "",
      hasPay: false,
    };
  },
  mounted() {
    //省市区数据
    // this.provinceAndCityDataPlus = regionDataPlus;
    //服务商地区
    this.$sapi.get("/api/v1/getAreaJson.json").then((res) => {
      this.provinceAndCityDataPlus = [
        { areaCode: "-1", areaName: "全部" },
        ...res.map((item) => {
          if (item.childList && !item.childList.length) {
            delete item.childList;
          } else {
            item.childList = [
              { areaCode: "-1", areaName: "全部" },
              ...item.childList.map((item2) => {
                if (item2.childList && !item2.childList.length) {
                  delete item2.childList;
                } else if (item2.childLis && item2.childList.lengtht) {
                  item2.childList = [
                    { areaCode: "-1", areaName: "全部" },
                    ...item2.childList.map((item3) => {
                      if (item3.childList && !item3.childList.length) {
                        delete item3.childList;
                      }
                      return item3;
                    }),
                  ];
                }
                return item2;
              }),
            ];
          }
          return item;
        }),
      ];
    });
  },
  watch: {
    hasPays(newval, oldval) {
      this.hasPay = newval;
    },
  },
  methods: {
    change() {
      //asc升序   desc降序
      let orderBy = "sort";
      if (this.sortActive == 2) {
        orderBy = `updateDate ${this.sortNewUp ? "asc" : "desc"}`;
      } else if (this.sortActive == 3) {
        orderBy = `viewCount ${this.sortHotUp ? "asc" : "desc"}`;
      } else if (this.sortActive == 4) {
        orderBy = `goodRate ${this.sortPriceUp ? "asc" : "desc"}`;
      }
      this.$emit("change", {
        areaCode: this.areaCode,
        orderBy,
        hasPay: this.hasPay,
      });
    },
    upChange(item) {},

    sortChange(index) {
      if (index == 1) {
        this.sortPriceUp = true;
        this.sortNewUp = false;
        this.sortHotUp = false;
      } else if (index == 2) {
        this.sortPriceUp = true;
        this.sortActive == index ? (this.sortNewUp = !this.sortNewUp) : null;
        this.sortHotUp = false;
      } else if (index == 3) {
        this.sortPriceUp = true;
        this.sortNewUp = false;
        this.sortActive == index ? (this.sortHotUp = !this.sortHotUp) : null;
      } else if (index == 4) {
        this.sortActive == index
          ? (this.sortPriceUp = !this.sortPriceUp)
          : null;
        this.sortNewUp = false;
        this.sortHotUp = false;
      }
      this.sortActive = index;
      this.change();
    },
    siteChange(arr) {
      let code = "";
      if (arr.length == 1 && arr[0] != "-1") {
        code = arr[0].substring(0, 3);
      } else if (arr.length == 2) {
        code = arr[1] != "-1"?arr[1].substring(0, 3):arr[0].substring(0, 3);
      } else if (arr.length == 3) {
        code = arr[2] != "-1"?arr[2].substring(0, 3):arr[1].substring(0, 3);
      }
      this.areaCode = code;
      this.change();
    },
  },
};
</script>
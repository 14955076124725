<template>
  <div class="filter">
    <div class="content">
      <div class="list">
        <filter-item
          name="一级分类"
          :active="classifyActive1"
          :list="classifyList1"
          :change="classifyChange1"
        />
        <filter-item
          name="二级分类"
          :active="classifyActive2"
          :list="classifyList2"
          :change="classifyChange2"
        />
        <filter-item
          name="二级分类"
          :active="classifyActive3"
          :list="classifyList3"
          :change="classifyChange3"
        />
        <!-- <div class="item" v-if="serversSiteList.length">
          <span>服务提供地：</span>
          <div>
            <div class="item-list">
              <span
                :class="[item.areaCode == serversSiteActive ? 'on' : '']"
                v-for="item in serversSiteList"
                :key="item.areaCode"
                @click="serversSiteChange(item)"
              >
                {{ item.areaName }}
              </span>
            </div>
            <div class="item-list" v-if="serversSiteList2.length">
              <span
                :class="[item.areaCode == serversSiteActive2 ? 'on' : '']"
                v-for="item in serversSiteList2"
                :key="item.areaCode"
                @click="serversSiteChange2(item)"
              >
                {{ item.areaName }}
              </span>
            </div>
            <div class="item-list" v-if="serversSiteList3.length">
              <span
                :class="[item.areaCode == serversSiteActive3 ? 'on' : '']"
                v-for="item in serversSiteList3"
                :key="item.areaCode"
                @click="serversSiteChange3(item)"
              >
                {{ item.areaName }}
              </span>
            </div>
          </div>
        </div> -->
        <filter-item
          name="服务商等级"
          :active="gradeActive"
          :list="gradeList"
          :change="gradeChange"
        />
        <!-- <div class="item" v-if="typesList.length">
          <span>工艺：</span>
          <div>
            <span
              :class="[item.dictValue == typesActive ? 'on' : '']"
              v-for="item in typesList"
              :key="item.dictValue"
              @click="typesChange(item)"
            >
              {{ item.dictLabel }}
            </span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import filterItem from "./filter-item";
export default {
  components: { filterItem },
  props: {
    gradeActives: {
      default: "-1",
      type: String,
    },
  },
  data() {
    return {
      typesActive: "-1",
      typesList: [],
      gradeList: [],
      serversSiteActive: "-1",
      serversSiteActive2: "-1",
      serversSiteActive3: "-1",
      serversSiteList: [],
      serversSiteList2: [],
      serversSiteList3: [],
      yzShopInfoRecommendList: [],
      classifyList: [],
      classifyList1: [],
      classifyActive1: "-1",
      classifyList2: [],
      classifyActive2: "-1",
      classifyList3: [],
      classifyActive3: "-1",
    };
  },
  computed: {
    gradeActive: {
      get() {
        return this.gradeActives;
      },
      set(val) {
        this.$emit("update:gradeActives", val);
      },
    },
  },
  methods: {
    change() {
      $(".filter .list .item").each(function () {
        if ($(this).find(".item-list-main").height() > 40) {
          $(this).find(".down").show();
        }
      });
      let categoryCode = "";
      if (this.classifyActive3 != "-1") {
        categoryCode = this.classifyActive3;
      } else if (this.classifyActive2 != "-1") {
        categoryCode = this.classifyActive2;
      } else if (this.classifyActive1 != "-1") {
        categoryCode = this.classifyActive1;
      }
      let areaCode = "";
      if (this.serversSiteActive3 != "-1") {
        areaCode = this.serversSiteActive3;
      } else if (this.serversSiteActive2 != "-1") {
        areaCode = this.serversSiteActive2;
      } else if (this.serversSiteActive != "-1") {
        areaCode = this.serversSiteActive;
      }
      this.$emit("change", {
        categoryCode,
        areaCode,
        yzCompanyAuthItems: this.gradeActive != "-1" ? this.gradeActive : "",
      });
    },
    async getDict() {
      //服务商等级
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_company_auth_items.json")
        .then((res) => {
          this.gradeList = [{ dictValue: "-1", dictLabel: "全部" }, ...res];
        });
      //获取服务分类
      this.getClassifyData();
    },
    typesChange(item) {
      this.typesActive = item.dictValue;
      this.change();
    },
    gradeChange(item) {
      this.gradeActive = item.dictValue;
      this.$nextTick(() => {
        this.change();
      });
    },
    serversSiteChange(item) {
      this.serversSiteActive = item.areaCode;
      if (item.areaCode != "-1" && item.childList.length) {
        this.serversSiteList2 = [
          { areaCode: "-1", areaName: "全部" },
          ...item.childList,
        ];
      } else {
        this.serversSiteList2 = [];
      }
      this.serversSiteList3 = [];
      this.serversSiteActive2 = "-1";
      this.change();
    },
    serversSiteChange2(item) {
      this.serversSiteActive2 = item.areaCode;
      if (item.areaCode != "-1" && item.childList.length) {
        this.serversSiteList3 =
          [{ areaCode: "-1", areaName: "全部" }, ...item.childList] || [];
      } else {
        this.serversSiteList3 = [];
      }
      this.serversSiteActive3 = "-1";
      this.change();
    },
    serversSiteChange3(item) {
      this.serversSiteActive3 = item.areaCode;
      this.change();
    },
    classifyChange1(item, is) {
      this.classifyActive1 = item.id;
      this.classifyList2 =
        item.id != "-1" && item.list && item.list.length ? item.list : [];
      this.classifyList3 = [];
      this.classifyActive2 = "-1";
      !is ? this.change() : null;
    },
    classifyChange2(item) {
      this.classifyActive2 = item.id;
      this.classifyList3 = item.list || [];
      this.change();
    },
    classifyChange3(item) {
      this.classifyActive3 = item.id;
      this.change();
    },
    getClassifyData() {
      this.$sapi.get("/api/v1/getYzTaskCategoryList.json").then((res) => {
        let _json = {};
        let pList = [];
        let list = res.filter((item) => {
          if (item.parentCode == "0") {
            _json[item.id] = [];
            pList.push(item);
          }
          return item.parentCode != "0";
        });
        list.forEach((item) => {
          if (_json[item.parentCode]) _json[item.parentCode].push(item);
        });
        let pid = this.$route.query.pid;
        let id = this.$route.query.id;
        let pItem = {};
        let pItems = {};
        this.classifyList1 = [
          { id: "-1", name: "全部" },
          ...pList.map((item) => {
            if (_json[item.id] && _json[item.id].length) {
              item.list = [{ id: "-1", name: "全部" }, ..._json[item.id]];
              item.list = item.list.map((items) => {
                if (id && items.id == id) {
                  pItems = items;
                }
                if (_json[items.id] && _json[items.id].length) {
                  items.list = [{ id: "-1", name: "全部" }, ..._json[items.id]];
                }
                return items;
              });
            }
            if (pid && item.id == pid) {
              pItem = item;
            }
            return item;
          }),
        ];
        if (pItem.name) {
          this.classifyChange1(pItem, pItems.name);
        }
        if (pItems.name) {
          this.classifyChange2(pItems);
        }
        //展开收缩
        this.$nextTick(() => {
          $(".filter .list .item").each(function () {
            if ($(this).find(".item-list-main").height() > 40) {
              $(this).find(".down").show();
            }
          });
          $(".filter .list .item .down").click(function () {
            if ($(this).hasClass("on")) {
              $(this).removeClass("on").find("font").html("展开");
              $(this)
                .find(".el-icon-caret-top")
                .removeClass("el-icon-caret-top")
                .addClass("el-icon-caret-bottom");
              $(this).parent().height(25);
            } else {
              $(this).addClass("on").find("font").html("收缩");
              $(this)
                .find(".el-icon-caret-bottom")
                .removeClass("el-icon-caret-bottom")
                .addClass("el-icon-caret-top");
              $(this)
                .parent()
                .height($(this).parent().find(".item-list-main").height());
            }
          });
        });
        console.log(this.classifyList1);
      });
    },
  },
  mounted() {
    //获取字典
    this.getDict();
  },
};
</script>